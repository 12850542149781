import React from 'react';
import ctaSectionStyle from './cta.section.module.css';
import pageStyle from '../assets/css/page.module.css';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import AOS from 'aos';
import 'aos/dist/aos.css';

class CtaSection extends React.Component {
  componentDidMount() {
    AOS.init();
  }
  render() {
    return (
      <div className={ctaSectionStyle.cta_section} data-aos="fade-up">
        <div className={ctaSectionStyle.cta_section_left}>
          <img src={this.props.img} alt="" />
        </div>
        <div className={ctaSectionStyle.cta_section_right}>
          <h3>{this.props.title}</h3>
          <h4>{this.props.subtitle}</h4>
          <div className={pageStyle.cta} style={{ textAlign: 'center' }}>
            <AniLink cover bg="#fba515" to="/contact/">
              {this.props.ctaText}
            </AniLink>
          </div>
        </div>
      </div>
    );
  }
}

export default CtaSection;
