import React from 'react';
import pageStyle from '../../assets/css/page.module.css';
import style from '../../assets/css/main.module.css';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import HeroPage from '../../components/hero.page';
import Hamburger from '../../components/hamburger';

import imgSEO from '../../assets/img/services/contenu.jpg';

import SimilarCard from '../../components/similar.card';

import similar1 from '../../assets/img/services/thumbnail/referencement.jpg';
import similar2 from '../../assets/img/services/thumbnail/website.jpg';
import similar3 from '../../assets/img/services/thumbnail/graphicdesign.jpg';
import similar4 from '../../assets/img/services/thumbnail/developpement.jpg';
import SEO from '../../components/seo';
import cta from '../../assets/img/illustrations/bookmarks.svg';
import CtaSection from '../../components/cta.section';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Mise à jour de contenu | Agence digitale | MDS Digital Agency "
      description="Vous proposez de nouveaux biens, services ? La mise à jour du contenu de votre site web ou de votre application mobile est très importante."
      keywords="mise à jour"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2>Mise à jour de contenu</h2>
      <p>
        Vous proposez de nouveaux biens, services ? Vous souhaitez communiquer au sujet de votre
        actualité ?
      </p>
      <p>
        Parce que vous l’avez certainement déjà experimenté vous-mêmes sur d’autres sites web, lire
        des informations obsolètes sur le site d’une entreprise a pour effet de discréditer cette
        société aux yeux des visiteurs. Il en va de même pour les moteurs de recherche qui
        pénalisent les contenus datés.
      </p>
      <p>La mise à jour de votre site web ou de votre application mobile est importante:</p>
      <ol>
        <li>
          <p>
            <strong>Gage de sérieux et de professionnalisme</strong>
          </p>
          <p>
            En faisant évoluer le contenu de votre site internet, vous démontrez à votre audience le
            dynamisme de votre entreprise. <br />
            La publication d’informations actualisées sur vos produits/services ou vos événements
            permet également d’attirer de (nouveaux) clients.
          </p>
        </li>
        <li>
          <p>
            <strong>Référencement sur les moteurs de recherche</strong>
          </p>
          <p>
            Les moteurs de recherche tels que Google accordent une grande importance à l’apport de
            contenu frais sur un site web. La mise à jour régulière de votre site internet contribue
            à en améliorer{' '}
            <AniLink cover bg="#fba515" to="/services/referencement/naturel/">
              le référencement naturel
            </AniLink>
            . Ceci a pour effet d’accroître la visibilité de votre site internet et ainsi
            d’augmenter le nombre de visiteurs sur votre site web.
          </p>
        </li>
      </ol>
      <p>
        Alors si la mise à jour de contenu est tellement importante, pourquoi voit-on tant de sites
        internet afficher des informations complètement obsolètes? La réponse est simple:{' '}
        <strong>les entreprises</strong> se concentrent sur les activités qui génèrent du chiffre
        d’affaire. Il leur reste en général
        <strong> peu de temps à investir dans leur communication</strong>.
      </p>
      <p>
        Confiez à MDS les <strong>mises à jour</strong> quotidiennes, hebdomadaires ou plus
        épisodiques de votre site internet ou de votre app mobile:
      </p>
      <ul>
        <li>
          Communiquez-nous les <strong>informations de base</strong>
        </li>
        <li>
          Nous nous chargerons de{' '}
          <strong>
            <AniLink cover bg="#fba515" to="/services/copywriting/">
              rédiger du contenu
            </AniLink>{' '}
            attractif et optimisé
          </strong>{' '}
          pour le référencement.
        </li>
        <li>
          Nous pouvons également trouver des <strong>photos</strong> qui illustrent votre propos ou
          créer les <strong>infographies</strong> correspondantes.
        </li>
        <li>
          Si cette mise à jour est susceptible de générer des <strong>conversions</strong>, nous
          pouvons réfléchir ensemble à des mécanismes efficaces à mettre en place sur votre site ou
          votre app.
        </li>
      </ul>
      <CtaSection
        img={cta}
        ctaText="Devis en ligne"
        title="Le service de mise à jour de contenu proposé est-il doté d’une grande flexibilité? Pourrais-je vous contacter à n’importe quel moment ?"
      />
      <p>Quelques exemples de contenus mis à jour régulièrement par MDS:</p>
      <ul>
        <li>
          <AniLink cover bg="#fba515" to="/realisations/toyota/">
            Toyota
          </AniLink>
        </li>
        <li>
          <AniLink cover bg="#fba515" to="/realisations/lexus/">
            Lexus
          </AniLink>
        </li>
      </ul>
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard text="Référencement" url="/services/referencement/" img={similar1} />
          <SimilarCard text="Création de sites internet" url="/services/website/" img={similar2} />
          <SimilarCard text="Graphic Design" url="/services/graphic-design/" img={similar3} />
          <SimilarCard text="Développement" url="/services/developpement/" img={similar4} />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
