import React from 'react';

import cardStyle from '../components/similar.card.module.css';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

class SimilarCard extends React.Component {
  componentDidMount() {
    const AOS = require('aos');
    this.aos = AOS;
    this.aos.init();
  }

  componentDidUpdate() {
    this.aos.refresh();
  }

  render() {
    return (
      <div className={cardStyle.card} data-aos="fade-up">
        <AniLink cover bg="#fba515" className={cardStyle.container} to={this.props.url}>
          <div
            className={cardStyle.img}
            style={{ backgroundImage: 'url(' + this.props.img + ')' }}
          />
          <div className={cardStyle.text}>
            <AniLink cover bg="#fba515" to={this.props.url}>
              {this.props.text}
            </AniLink>
          </div>
        </AniLink>
      </div>
    );
  }
}

export default SimilarCard;
